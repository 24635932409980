<template>
  <Candles />
</template>

<script>
import Candles from './components/Candles.vue';

export default {
  name: 'App',
  components: {
    Candles
  }
}
</script>

<style>
body {
  margin: 0;
  background-color: #000000;
}
#app {
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
