<template>
  <div id="comp" v-bind:class="{ 'modal-active': modal.active }">
    <button v-on:click="showModal()" id="candleButton" aria-label="Digitale Gedenkkerze zünden"></button>
    <div id="content-container" v-bind:class="{ 'active': contentContainerActive }">
      <div class="close-content-icon" v-on:click="closeContent()">
        <span></span>
        <span></span>
      </div>
      <div id="content"></div>
    </div>
    <div class="nav-mobile-icon" v-bind:class="{ 'open': mobileMenuOpen }" v-on:click="openMobileMenu()">
      <span></span>
      <span></span>
    </div>
    <nav class="nav-container" role="navigation" aria-label="Navigation">
      <ul class="nav">
        <li>
          <a href="#" v-on:click='openContent("about")'>Über das Projekt</a>
        </li>
        <li>
          <a href="#" v-on:click='openContent("privacypolicy")'>Datenschutz</a>
        </li>
        <li>
          <a href="#" v-on:click='openContent("imprint")'>Impressum</a>
        </li>
      </ul>
    </nav>
    <img id="dot" src="dot_bright_new.png" v-bind:class="{ 'animation-active': modal.dotAnimationActive }" alt="Lichtpunkt">
    <div id="infobox">
      <div id="personInfo">Wählen Sie eine Kerze aus, um zu sehen, wem Sie gewidmet ist.</div>
    </div>
    <div id="modal">
      <div class="modal-row">
        <div class="modal-candle">
          <video width="400" height="400" id="candle-video" playsinline preload muted="muted" v-on:click="lightCandle()" aria-label="Digitale Gedenkkerze jetzt zünden">
            <source src="video/candle_2p500.webm" type="video/webm">
            <source src="video/candle_hevc.mov" type="video/quicktime">
            <source src="video/candle_mp4.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="modal-text">
          <div class="modal-welcome-message" v-bind:class="{ 'active': modal.welcomeActive }">
            <p>Klicken Sie auf den Docht und zünden Sie damit eine digitale Gedenkkerze für einen aus Frankfurt deportierten Juden an.</p>
          </div>
          <div class="modal-waiting-message" v-bind:class="{ 'active': modal.waitingforCandle }">
            <p>Warte auf Antwort...</p>
          </div>
          <div class="modal-person-data" v-bind:class="{ 'active': modal.personDataActive }">
            <h3 class="person-data-title">Ihre Gedenkkerze brennt für:</h3>
            <div class="person-data-name"></div>
            <div class="person-data-born"></div>
            <div class="person-data-died"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import io from "socket.io-client";
import * as PIXI from 'pixi.js';
import { content } from '../website_content.js';

window.vm = {};
var resizeTimer;

export default {
  name: 'Candles',
  data() {
    return {
      socket: {},
      context: {},
      position: {
        x: 0,
        y: 0
      },
      candles: {},
      otherCandles: [],
      pcandles: [],
      pixiApp: {},
      sprites: {},
      dotScaleFactor: 1,
      numScaleFactor: 1,
      modal: {
        active: false,
        welcomeActive: true,
        personDataActive: false,
        waitingforCandle: false,
        dotAnimationActive: false,
        requestLocked: false
      },
      mobileMenuOpen: false,
      content: content,
      contentContainerActive: false
    }
  },
  created() {

    if(location.hostname == "yc.schunckdoelker.de"){
      this.socket = io("https://yc.schunckdoelker.de", { 
        path: '/socket/' 
      });
    } else if(location.hostname == "www.yellowcandleffm.de") {
      this.socket = io("https://www.yellowcandleffm.de", { 
        path: '/socket/' 
      });
    } else if(location.hostname == "yellowcandleffm.de") {
      this.socket = io("https://yellowcandleffm.de", { 
        path: '/socket/' 
      });
    } else {
      this.socket = io("http://localhost:5000", { 
        path: '/socket/' 
      });
    }

    window.vm.candles = this;

    // window.addEventListener('resize', this.resizePixi);

    window.addEventListener('resize', function() {

      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {

        window.vm.candles.resizePixi();
                
      }, 500);

    });
    
  },
  mounted() {
    
    // Initialize Pixi
    this.initPixi();

    // Preload video
    this.preloadVideo();
    
    // Initialize socket
    this.socket.on("candles", data => {
      // populate global candle store
      this.candles = data;
      // enrich data for all candles
      for (var index in this.candles){
        this.candles[index] = this.enrichCandleData(this.candles[index]);
      }
      // draw candles
      this.drawCandles();
    }),
    this.socket.on("candle", candle => {
      // add to candles array
      candle = this.enrichCandleData(candle);
      this.candles.push(candle);
      if(this.modal.waitingforCandle === true && candle.myCandle === true) {
        // my candle! show animation and stuff
        this.introduceCandle(candle);
      } else {
        // other candle, draw it directly.
        // this.drawCandle(candle);
        this.animateOtherDot(candle);
      }
      // console.log(JSON.stringify(candle)); 
    }),
    this.socket.on("error", data => {
      const error = data;
      alert(error);
      if(this.modal.waitingforCandle === true){
        // handle error in modal
      }
    });

  },
  methods: {
    move(direction) {
      this.socket.emit("move", direction);
    },
    initPixi() {
      // initialize pixi stuff
      this.pixiApp = new PIXI.Application({width: (window.innerWidth), height: (window.innerHeight), autoDensity: true, resolution: window.devicePixelRatio });
      document.getElementById("comp").appendChild(this.pixiApp.view);
      this.sprites = new PIXI.Container({
          scale: true,
          position: true,
          rotation: true,
          uvs: true,
          alpha: true,
          sortableChildren: true
        });
      this.pixiApp.stage.addChild(this.sprites);
      this.pixiApp.renderer.plugins.interaction.moveWhenInside = true;

      // set dotScaleFactor
      let area = (window.innerWidth - 72) * (window.innerHeight - 108);
      this.dotScaleFactor = Math.sqrt(area / (1000*1000));
    },
    destroyPixi(){
      this.pixiApp.destroy({children:true, texture:true, baseTexture:true});
    },
    resizePixi(){
      if(typeof(this.pixiApp.destroy) == 'function'){
        this.destroyPixi();
        this.initPixi();
        this.drawCandles();
      }
    },
    addCandle() {
      this.socket.emit("addCandle", "now");
    },
    animateDotRandom() {
      document.getElementById("dot").style.top = Math.round(Math.random() * window.innerHeight) + "px";
      document.getElementById("dot").style.left = Math.round(Math.random() * window.innerWidth) + "px";
    },
    enrichCandleData(candle) {
      // build person data
      let birthname = '';
      if(candle.birthname != undefined) {
        birthname = ', geb.&nbsp;' + candle.birthname;
      }
      let born = '';
      if(candle.birthday != undefined){
        let birthdayDate = new Date (candle.birthday);
        born = '<span class="geboren"></span>' + [birthdayDate.getDate(), (birthdayDate.getMonth() + 1), birthdayDate.getFullYear()].join('.');
      }
      let died = '';
      if(candle.deathday != undefined){
        let deathdayDate = new Date (candle.deathday);
        died = [deathdayDate.getDate(), (deathdayDate.getMonth() + 1), deathdayDate.getFullYear()].join('.');
      }
      if(candle.deathday != undefined && candle.deathdayStr != undefined){
        died = candle.deathdayStr;
      }
      if(candle.deathLocation != undefined){
        if(candle.deathLocation == "Freitod"){
          died = died + ' durch Freitod';
        } else {
          died = died + ' in ' + candle.deathLocation;
        }
      }
      if(died != ''){
        died = '<span class="gestorben"></span>' + died;
      }
      if(born != '' && died != ''){
        died = ' ' + died;
      }
      let borndied = '';
      if(born != '' || died != ''){
        // borndied = ' (' + born + died + ')';
        borndied = '<br/>' + born + died;
      }

      candle.displayName = '<strong>' + [candle.firstname, candle.lastname].join(' ') + birthname + '</strong>'
      candle.displayBorn = born;
      candle.displayName
      candle.displayDied = died;

      candle.nameStr = '<span class="name"><strong>' + [candle.firstname, candle.lastname].join(' ') + '</strong>' 
        + birthname + '</span>' + borndied;

      return candle;
    },
    drawCandle(candle) {

      const textureYellow = PIXI.Texture.from('dot_yellow.png');
      const textureBright = PIXI.Texture.from('dot_bright_new.png');

      // create a new Sprite
      const pcandle = PIXI.Sprite.from(textureYellow);

      // set the anchor point so the texture is centerd on the  sprite
      pcandle.anchor.set(0.5);

      // build createdAt timestamp
      if(candle.createdAt !== undefined){
        pcandle.createdAt = + new Date (candle.createdAt);
      }

      // add nameStr
      pcandle.nameStr = candle.nameStr;

      // position candle
      pcandle.x = (candle.position.x / 1000 * (this.pixiApp.screen.width - 72)) + 72;
      pcandle.y = (candle.position.y / 1000 * (this.pixiApp.screen.height - 108)) + 46;

      if(candle.myCandle === true){
        // make this mine by setting a cookie
        this.makeMyCandle(candle._id);
      }

      if(this.isMyCandle(candle._id)){
        // hightlight my candle
        pcandle.texture = textureBright;
        // pcandle.scale.set(0.75);
        // pcandle.hitArea = new PIXI.Circle(0,0,10,10);
        pcandle.myCandle = true;
        pcandle.zIndex = (candle.rand + 20000);
      } else {
        pcandle.zIndex = candle.rand;
      }
      
      // set candle size
      this.setCandleSize(pcandle);

      // Opt-in to interactivity
      pcandle.interactive = true;

      // Shows hand cursor
      pcandle.buttonMode = true;

      function hover() {
        document.getElementById("personInfo").innerHTML = this.nameStr;
      }

      function unhover() {
        this.scale.set(this.oldScale);
        this.zIndex = this.oldZindex;
        this.hitArea = this.oldHitArea;
        document.getElementById("personInfo").innerHTML = "Wählen Sie eine Kerze aus, um zu sehen, wem Sie gewidmet ist.";
        this.active = false;
      }

      function click() {
        if(this.active !== true){
          this.oldScale = this.scale.x;
          this.oldHitArea = this.hitArea;
          // this.oldTint = this.tint;
          this.oldZindex = this.zIndex;
          // this.zIndex = 9999999;
          // this.tint = Math.random() * 0xE8D4CD;
          this.scale.set(this.oldScale * 2);
          // this.hitArea = new PIXI.Circle(0,0,50,50);
        }
        this.active = true;
        document.getElementById("personInfo").innerHTML = this.nameStr;
      }

      // Pointers normalize touch and mouse
      pcandle.on('mousedown', click)
        .on('touchstart', click)
        .on('touchmove', hover)
        .on('mouseup', unhover)
        .on('touchend', unhover)
        .on('mouseupoutside', unhover)
        .on('touchendoutside', unhover)
        .on('mouseover', click)
        .on('mouseout', unhover);
      // pcandle.on('pointerover', hover);
      // pcandle.on('pointerout', unhover);
      // pcandle.on('mousedown', click);
      // candle.on('touchstart', hover);
      // candle.on('touchend', unhover);

      // finally we push the candle into the candles array so it it can be easily accessed later
      this.pcandles.push(pcandle);
      this.sprites.addChild(pcandle);
    },
    drawCandles() {
      // update numScaleFactor
      this.numScaleFactor = Math.sqrt(Math.sqrt(11908 / this.candles.length));

      // destroy sprites to free memory
      this.sprites.destroy({children:true, texture:true, baseTexture:true});
      this.pixiApp.stage.removeChild(this.sprites);
      // console.log("removed sprites");
      // initialize sprites container
      this.sprites = new PIXI.Container({
        scale: true,
        position: true,
        rotation: true,
        uvs: true,
        alpha: true,
        sortableChildren: true
      });
      // add sprites to stage
      this.pixiApp.stage.addChild(this.sprites);
      // draw all candles
      for (var index in this.candles){
        // console.log(JSON.stringify(candle));     
        this.drawCandle(this.candles[index]);
      }
      this.sprites.sortChildren();
    },
    setCandleSize(pcandle) {
      // set size of this pixi candle according to sizing voodoo logic

      // initialize time range and scaling factors to map the time to
      const now = + new Date();
      // const oldest = + new Date("2021-03-22T18:00:16.672+00:00");
      const oldest = + new Date("2021-04-07T07:40:57.781+00:00");
      const scaleMin = 0.14 / 3;
      const scaleMax = 0.3 / 3;

      // build random creadtedAt date if there is none (testing only)
      if(pcandle.createdAt === undefined){
        pcandle.createdAt = oldest + (Math.random() * (now - oldest));
      }

      let myCandleFactor = 1;
      let myCandleHitAreaFactor = 1;

      if(pcandle.myCandle === true){
        myCandleFactor = 8;
        myCandleHitAreaFactor = 0.5;
      } else {
        myCandleFactor = this.numScaleFactor;
        myCandleHitAreaFactor = 0.5;
      }

      let size = this.dotScaleFactor * myCandleFactor * (scaleMin + (((pcandle.createdAt - oldest)/(now - oldest)) * (scaleMax - scaleMin)));
      // let size = this.dotScaleFactor * myCandleFactor * (scaleMin + (((pcandle.createdAt - oldest)/(now - oldest)) * (scaleMax - scaleMin)));


      pcandle.scale.set(size);

      let hitAreaRadius = 100 * myCandleHitAreaFactor;
      pcandle.hitArea = new PIXI.Circle(0,0,hitAreaRadius,hitAreaRadius);

    },
    makeMyCandle(id) {
      if(this.$cookies.isKey('myCandles')){
        let myCandles = JSON.parse(this.$cookies.get('myCandles'));
        if(myCandles.indexOf(id) === -1){
          myCandles.push(id);
          this.$cookies.set('myCandles', JSON.stringify(myCandles), "1y");
        }
      } else {
        let myCandles = [];
        myCandles.push(id);
        this.$cookies.set('myCandles', JSON.stringify(myCandles), "1y");
      }
    },
    isMyCandle(id) {
      if(this.$cookies.isKey('myCandles')){
        let myCandles = JSON.parse(this.$cookies.get('myCandles'));
        if(myCandles.indexOf(id) === -1){
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    lightCandle() {

      // only procede if we are not already waiting
      if(this.modal.requestLocked === false){
        // show waiting animation?
        this.addCandle();
        this.modal.waitingforCandle = true;

        // lock to prevent spamming
        this.modal.requestLocked = true;

        document.getElementById("candle-video").play();
        this.modal.welcomeActive = false;
      }
    },
    introduceCandle(candle) {
      // animate "drawing" of candle

      // populate person data
      document.getElementsByClassName("person-data-name")[0].innerHTML = candle.displayName;
      document.getElementsByClassName("person-data-born")[0].innerHTML = candle.displayBorn;
      document.getElementsByClassName("person-data-died")[0].innerHTML = candle.displayDied;

      // hide waiting message/animation
      this.modal.waitingforCandle = false;

      // show person data
      this.modal.personDataActive = true;

      this.animateDot(candle);
    },
    animateDot(candle) {
      let rect = document.getElementById("candle-video").getBoundingClientRect();
      let from_x = rect.left + 200;
      let from_y = rect.top + 120;

      // set dot start position
      document.getElementById("dot").style.left = from_x + "px";
      document.getElementById("dot").style.top = from_y + "px";

      // set destination position;
      let to_x = 72 + (candle.position.x / 1000 * (this.pixiApp.screen.width - 72));
      let to_y = 46 + (candle.position.y / 1000 * (this.pixiApp.screen.height - 108));

      // activate dot
      setTimeout(this.activateDot, 200);

      // fade in after 2s
      setTimeout(function(){
        document.getElementById("dot").style.opacity = 1;
        document.getElementById("dot").style.transform = "scale(2)";
      }, 2000); 

      // start animation after 5s
      setTimeout(function(){
        document.getElementById("dot").style.left = to_x + "px";
        document.getElementById("dot").style.top = to_y + "px";
      }, 7000); 

      // draw candle on canvas in the background after finished animation
      setTimeout(this.drawCandle.bind(null, candle), 12000); 

      // hide dot and modal and reset modal views
      setTimeout(this.endAnimation, 13500); 
    },
    activateDot() {
      this.modal.dotAnimationActive = true;
    },
    endAnimation() {
      this.modal.dotAnimationActive = false;
      this.modal.active = false;
      setTimeout(this.resetModal, 2200);

      // fade dot out after 5s
      setTimeout(function(){
        document.getElementById("dot").style.opacity = 0;
        document.getElementById("dot").style.transform = "scale(1)";
      }, 1000); 
    },
    resetModal() {
      this.modal.personDataActive = false;
      this.modal.welcomeActive = true;
      document.getElementById("candle-video").load();
      this.modal.requestLocked = false;
    },
    showModal() {
      if(this.modal.active === true){
        this.modal.active = false;
        this.resetModal();
      } else {
        this.resetModal();
        this.modal.active = true;
      }
    },
    preloadVideo() {
      document.getElementById("candle-video").play();
    },
    openMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
      this.contentContainerActive = false;
    },
    openContent(content) {
      document.getElementById("content").innerHTML = this.content[content];
      this.contentContainerActive = true;
    },
    closeContent() {
      this.contentContainerActive = false;
    }, 
    animateOtherDot(candle) {
      // animates dots for candles generated by other persons during runtime

      function getRandomInt(max) {
        return Math.floor(Math.random() * max);
      }

      // only animate if it has not been animated before
      if(this.otherCandles.includes(candle) === false){

        // add candle to otherCandles array
        this.otherCandles.push(candle);

        // generate random starting position outside viewport
        let from_x = 0;
        let from_y = 0;
        let num_1 = 0;
        let num_2 = 0;

        if(getRandomInt(2) === 1){
          num_1 = -150;
        } else {
          num_1 = window.innerWidth + 150;
        }

        num_2 = (getRandomInt((window.innerWidth + 300)) - 150);

        from_x = num_2;
        from_y = num_1;

        // build unique candle id consisting of string "candle" and id
        let otherCandleId = "candle" + candle.id;

        // build dot element
        var otherCandle = document.createElement("img");
        otherCandle.setAttribute("id", otherCandleId);
        otherCandle.setAttribute("src", "dot_bright_yellow.png");
        otherCandle.setAttribute("class", "other-candle-dot");

        // set dot start position
        otherCandle.style.left = from_x + "px";
        otherCandle.style.top = from_y + "px";

        // append dot to DOM
        document.getElementById("comp").appendChild(otherCandle);

        // set destination position;
        let to_x = 72 + (candle.position.x / 1000 * (this.pixiApp.screen.width - 72));
        let to_y = 46 + (candle.position.y / 1000 * (this.pixiApp.screen.height - 108));

        // activate dot
        setTimeout(function(){
          otherCandle.classList.add("animation-active");
        }, 200);

        // fade in after 2s
        setTimeout(function(){
          otherCandle.style.opacity = 1;
          otherCandle.style.transform = "scale(2)";
        }, 2000); 

        // start animation after 5s
        setTimeout(function(){
          otherCandle.style.left = to_x + "px";
          otherCandle.style.top = to_y + "px";
        }, 7000); 

        // draw candle on canvas in the background after finished animation
        setTimeout(this.drawCandle.bind(null, candle), 12000); 

        // hide dot
        setTimeout(function(){
          otherCandle.classList.remove("animation-active");
        }, 12500); 

        // end animation
        setTimeout(function(){
          // otherCandle.style.opacity = 0;
          otherCandle.style.transform = "scale(0.01)";
        }, 13500); 

        // remove dot from dom
        setTimeout(function(){
          document.getElementById("comp").removeChild(otherCandle);
          otherCandle = null;
        }, 18500); 

      }
    }
  }
}
</script>

<style lang="scss">
@import "../scss/main.scss";
</style>
